import './App.scss';

import React from 'react';
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className='App'>
      </div>
    );
  }
}

export default App;
